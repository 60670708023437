/*
 * Based on animate-it script to add/remove animation clases
 *
 * https://plugins.trac.wordpress.org/browser/animate-it/trunk/assets/js/edsanimate.js
 */
(function($) {

  $(document).ready(function(){
    var animationStyleClasses = ["animated", "bounce", "flash", "pulse", "rubberBand", "shake", "swing", "tada", "wobble", "bounceIn", "bounceInDown", "bounceInLeft", "bounceInRight", "bounceInUp", "bounceOut", "bounceOutDown", "bounceOutLeft", "bounceOutRight", "bounceOutUp", "fadeIn", "fadeInDown", "fadeInDownBig", "fadeInLeft", "fadeInLeftBig", "fadeInRight", "fadeInRightBig", "fadeInUp", "fadeInUpBig", "fadeOut", "fadeOutDown", "fadeOutDownBig", "fadeOutLeft", "fadeOutLeftBig", "fadeOutRight", "fadeOutRightBig", "fadeOutUp", "fadeOutUpBig", "flip", "flipInX", "flipInY", "flipOutX", "flipOutY", "lightSpeedIn", "lightSpeedOut", "rotateIn", "rotateInDownLeft", "rotateInDownRight", "rotateInUpLeft", "rotateInUpRight", "rotateOut", "rotateOutDownLeft", "rotateOutDownRight", "rotateOutUpLeft", "rotateOutUpRight", "hinge", "rollIn", "rollOut", "zoomIn", "zoomInDown", "zoomInLeft", "zoomInRight", "zoomInUp", "zoomOut", "zoomOutDown", "zoomOutLeft", "zoomOutRight", "zoomOutUp"];
    var delayClasses  = ["delay1", "delay2", "delay3", "delay4", "delay5", "delay6", "delay7", "delay8", "delay9", "delay10", "delay11", "delay12"];
    var scrollOffset = edsanimate_options.offset;


    var windowLastPosition = 0;
    var $window = $(window);

    $window.on('scroll', function() {
      var windowCurrentPosition = $window.scrollTop();
      if ( windowCurrentPosition < windowLastPosition) {
        reprogramAnimations();
      }
      windowLastPosition = windowCurrentPosition;
    });


    function reprogramAnimations() {
      $('.eds-scroll-visible').each(function(i, module) {
        $module = $(module);
        $module.removeClass('.eds-scroll-visible');

        var classesToAdd = " eds-scroll-visible ";

        $module.removeClass(function(index, className){
          var removeClassList = "";
          var classList = className.trim().split(/\s+/);
          $.each(classList, function(index, value){
            if(($.inArray(value, animationStyleClasses)!==-1)
              || ($.inArray(value, delayClasses)!==-1))
              removeClassList += " " + value;
          });

          classesToAdd += removeClassList;
          return removeClassList;
        });

        $module.addClass("eds-scroll-hidden");

        $module.edsViewportChecker({
          classToAdd: classesToAdd,
          offset: scrollOffset
        });

      });

    }

  });

})(jQuery);